<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-subheader>
          <v-row>
            <v-col>Product List</v-col>

            <v-col cols="6">
              <v-btn class="mr-5" to="/admin/products/category/list" outlined>Catagories</v-btn>
              <v-btn to="/admin/products/add" color="success" outlined>Add Product</v-btn>
            </v-col>
          </v-row>
        </v-subheader>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-card flat>
            <v-card-title>
              <!-- Table Title  -->
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
            </v-card-title>
            <v-progress-linear :indeterminate="loading"></v-progress-linear>
            <v-data-table :headers="headers" :items="allProducts" :search="search">
              <template v-slot:item.status="{ item }">
                <v-chip outlined :color="item.active ? 'success' : 'error'">
                  {{
                  item.active ? "Online" : "Offline"
                  }}
                </v-chip>
              </template>
              <template v-slot:item.action="{ item }">
                <v-icon small class="mr-2" @click="editProduct(item.id)">edit</v-icon>
                <v-icon small @click="removeProduct(item.id)">delete</v-icon>
              </template>
            </v-data-table>
          </v-card>

          <!-- Snackbar Notification Start -->
          <v-snackbar v-model="snackbar" :color="snackbarColor">
            {{ snackbarMessage }}
            <v-btn dark flat @click="snackbar = false">Close</v-btn>
          </v-snackbar>
          <!-- Snackbar Notification Ends -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ProductList",
  data() {
    return {
      loading: false, //Loading
      //Snackbar
      snackbar: false,
      snackbarMessage: null,
      snackbarColor: "",
      search: "",
      headers: [
        {
          text: "SKU",
          align: "left",
          sortable: false,
          value: "sku"
        },
        { text: "Name", value: "name" },
        { text: "Price", value: "price" },
        { text: "Stock", value: "stock" },
        { text: "Category", value: "category.name" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action" }
      ]
    };
  },
  computed: {
    ...mapGetters(["allProducts", "allProductCategories"])
  },
  methods: {
    ...mapActions(["bind", "delete"]),
    fetchProducts() {
      if (!this.allProducts) {
        this.bind({
          collection: "products",
          data: "products"
        })
          .then(() => {
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.snackbarMessage = "Please check your internet connection";
            this.snackbarColor = "error";
            this.snackbar = true;
          });
      } else {
        this.loading = false;
      }
    },

    removeProduct(id) {
      // Delete Product
      this.delete({
        collection: "products",
        doc: id
      });
    },

    editProduct(id) {
      this.$router.replace("/admin/products/" + id);
    }
  },
  created() {
    this.loading = true;
    this.fetchProducts();
  }
};
</script>
